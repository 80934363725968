<template>
  <div class="h-full">
    <div class="console-dash-menu-container">
      <div class="console-dash-menu">
        <u-btn @click="$router.push({name: 'fenaju.registros.registro'})" class="opt-btn" :class="{active: $route.name === 'fenaju.registros.registro'}"><i class="fa-duotone fa-user" /> Resumo</u-btn>
        <u-btn @click="$router.push({name: 'fenaju.registros.registro.financeiro'})" class="opt-btn" :class="{active: $route.name === 'fenaju.registros.registro.financeiro'}"><i class="fa-duotone fa-money-check" /> Financeiro</u-btn>
      </div>
    </div>

    <div class="suporte-cliente-tarefas cli-task-page" v-if="$route.name === 'fenaju.registros.registro'">
      <div class="nav">
        <a href="/#/fenaju">Console</a>
        <i class="fa fa-chevron-right" />
        <a href="/#/fenaju/cilentes/lista">Registros</a>
        <i class="fa fa-chevron-right" />
        <span class="nav-end">Registro ID #{{cliente && cliente.id}}</span>
      </div>

      <div v-if="!loading && cliente" class="case">
        <div class="case-header">
          <div class="id">
            Registro {{cliente.dominio}}
          </div>
        </div>
        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Detalhes do cliente</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
                <cliente-menu-right />
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <e-row class="def full-width" mr>
              <e-col>
                <e-row mr>
                  <e-col>
                    <task-detail title="Nome">{{ cliente.solicitante.name }}</task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Domínio principal">
                      <a :href="'https://' + cliente.dominio" target="_blank">{{cliente.dominio}}</a>
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Documento">{{ cliente.solicitante.document|formataCpfCnpj2 }}</task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Tipo do cliente">
                      {{ cliente.solicitante.type === 1 ? 'Pessoa Física' : 'Pessoa Jurídica' }}
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="E-mail">
                      <div class="lowercase" v-if="cliente.solicitante.emails.length">{{ cliente.solicitante.emails.map(e => e.email).join(', ') }}</div>
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Telefone">
                      <div v-if="cliente.solicitante.phoneNumbers.length">{{ cliente.solicitante.phoneNumbers.map(e => e.phoneNumber).join(', ') }}</div>
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Criado">{{ cliente.createdAt|formatDate }}</task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Última atualização">
                      <div v-if="cliente.dateModified">{{ cliente.dateModified|formatDate }}</div>
                      <div v-else>-</div>
                    </task-detail>
                  </e-col>
                </e-row>
              </e-col>
              <e-col>
                <e-row mr>
                  <e-col>
                    <task-detail title="Status">
                      <div :class="'dominio-status-code-' + cliente.status" :style="'color: ' + status[cliente.status].color">{{status[cliente.status].label}}</div>
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="">
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row class="" mr>
                  <e-col>
                    <task-detail title="Data do Registro">
                      <div v-if="cliente.dataRegistro">{{cliente.dataRegistro|formatDate('dd/MM/yyyy')}}</div>
                      <div v-else>-</div>
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Data de Expiração">
                      <div v-if="cliente.dataExpiracao">{{cliente.dataExpiracao|formatDate('dd/MM/yyyy')}}</div>
                      <div v-else>-</div>
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Taxa de Registro">
                      R$ {{cliente.valorRegistro|moeda}}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Taxa de Serviço">
                      R$ {{cliente.valorServico|moeda}}
                    </task-detail>
                  </e-col>
                </e-row>
              </e-col>
            </e-row>
          </div>
        </div>

        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Observações sobre o cliente</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <div style="padding-bottom: 30px">
              <div v-if="cliente.observacao">{{cliente.observacao}}</div>
              <div v-else>Nada informado</div>
            </div>
          </div>
        </div>

        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Ordens de Serviço</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <div style="padding-bottom: 30px; width: 100%">
              <clientes-os :cliente="cliente" />
            </div>
          </div>
        </div>

        <!-- -->
      </div>
      <div v-else class="case">
        <sl-loading class="size-18" content="" />
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>

import SlLoading from "components/layout/components/Loading.vue"
import {find} from "@/domain/fenaju/services"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import TaskDetail from "components/suporte/cliente/components/include/TaskDetail.vue";
import ClienteMenuRight from "components/clientes/components/cliente/include/ClienteMenuRight.vue";
import clienteWindow from "components/fenaju/components/window/registroWindow";
import GerenteEdit from "components/clientes/components/cliente/include/GerenteEdit.vue";
import StatusCliente from "components/clientes/components/cliente/include/StatusCliente.vue";
import {status} from "@/domain/fenaju/helpers"
import ClientesOs from "components/fenaju/components/clientes/dashboard/OrdemServico.vue";

export default {
  name: "RegistroCliente",
  provide: function () {
    return {
      clienteContainer: this,
    }
  },
  mixins: [],
  computed: {
    status () {
      /*return Object.keys(status).map(k => {
        const v = status[k]
        return {
          ...v,
          value: Number(k)
        }
      })*/
      return status
    }
  },
  data () {
    return {
      loading: true,
      cliente: null
    }
  },
  components: {
    ClientesOs,
    ClienteMenuRight,
    TaskDetail,
    ERow,
    ECol,
    SlLoading
  },
  beforeCreate() {
    document.body.classList.add('cli-task-body')
    document.body.classList.add('cli-page-body')
  },
  beforeDestroy() {
    document.body.classList.remove('cli-task-body')
    document.body.classList.remove('cli-page-body')
  },
  mounted() {
    const element = document.querySelector('.u-erp-layout-container');
    element.scrollTop = 0;
    this.load()
  },
  watch: {
    '$route.params.id' (v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  methods: {
    load() {
      this.loading = true
      find(this.$route.params.id)
          .then(response => {
            this.cliente = response.data
            setTimeout(() => {
              document.title = `Cliente #${this.cliente.solicitante.name} - SL`
            }, 2)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    clienteWindow,
    edit () {
      this.clienteWindow(this.cliente.id)
    }
  },
  meta: {
    title: 'Cliente'
  }
}
</script>

<style lang="stylus">
.cli-page-body {
  .console-dash-menu-level1 {
    display none
  }
  .fin-container {
    background transparent !important
  }
}
</style>
